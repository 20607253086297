import { IDefaultTableColumnConfig, ILeadsSummaryConfig, IDualAxisLineGraphConfig, INameValuePair } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsSummaryConfig implements ILeadsSummaryConfig {

    public metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' },
        { name: 'Trend', value: 'Trend' }
    ];

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: 'Total Sales Leads',
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.responseRate.metricId,
                displayName: 'Response Rate',
                propertyName: Enums.leadMetrics.responseRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.responseRate30.metricId,
                displayName: 'Response Rate < 30 Min',
                propertyName: Enums.leadMetrics.responseRate30.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.responseRate45.metricId,
                displayName: 'Response Rate < 45 Min',
                propertyName: Enums.leadMetrics.responseRate45.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.responseRate60.metricId,
                displayName: 'Response Rate < 1 hr',
                propertyName: Enums.leadMetrics.responseRate60.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.averageResponseTime48Hour.metricId,
                displayName: 'Avg. Response Time (48 Hrs)',
                propertyName: Enums.leadMetrics.averageResponseTime48Hour.nameKey,
                metricFormatKey: constants.formatKeys.minutesTimeString
            },
            {
                id: Enums.leadMetrics.averageResponseTime.metricId,
                displayName: 'Overall Average Response Time',
                propertyName: Enums.leadMetrics.averageResponseTime.nameKey,
                metricFormatKey: constants.formatKeys.minutesTimeString
            },
            // Lead Sales
            {
                id: Enums.leadMetrics.leadSales.metricId,
                displayName: 'Lead Sales',
                propertyName: 'bestMatchSales',
                metricFormatKey: constants.formatKeys.localeString
            },
            // Lost Opportunities
            {
                id: Enums.leadMetrics.lostSales.metricId,
                displayName: 'Lost Opportunities',
                propertyName: 'lostDealerSales',
                metricFormatKey: constants.formatKeys.localeString
            },
            // Close Rate
            {
                id: Enums.leadMetrics.closeRate.metricId,
                displayName: 'Close Rate',
                propertyName: 'bestMatchCloseRate',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // 30 Day Close Rate
            {
                id: Enums.leadMetrics.closeRate30.metricId,
                displayName: '30-Day Close Rate',
                propertyName: 'bestMatchCloseRate30',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // 60 Day Close Rate
            {
                id: Enums.leadMetrics.closeRate60.metricId,
                displayName: '60-Day Close Rate',
              propertyName: 'bestMatchCloseRate60',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // 90 Day Close Rate
            {
                id: Enums.leadMetrics.closeRate90.metricId,
                displayName: '90-Day Close Rate',
                propertyName: 'bestMatchCloseRate90',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // 120 Day Close Rate
            {
                id: Enums.leadMetrics.closeRate120.metricId,
                displayName: '120-Day Close Rate',
                propertyName: 'bestMatchCloseRate120',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
              id: Enums.leadMetrics.appointments.metricId,
              displayName: Enums.leadMetrics.appointments.name,
              propertyName: Enums.leadMetrics.appointments.nameKey,
              metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.appointmentRate.metricId,
                displayName: Enums.leadMetrics.appointmentRate.name,
                propertyName: Enums.leadMetrics.appointmentRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Average Communications
            {
                id: Enums.leadMetrics.averageCommunications.metricId,
                displayName: 'Avg. Communications',
                propertyName: 'averageCommunications',
                metricFormatKey: constants.formatKeys.roundToTenth
            },
        ],
        defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
        defaultMetricTwoId: Enums.leadMetrics.responseRate.metricId,
        metricOneColorHexCode: '#D0573a',
        metricTwoColorHexCode: '#646464'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
            // canDrillthrough: (row) => this.helperService.canDrillthrough(this.filterModel, row.entityType)
        },
        {
            show: false,
            print: true,
            header: 'Retailer Name',
            columnDef: 'displayName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
        },
        // can use row to determine entity type if needed

        {
            show: true,
            header: 'Total Sales Leads',
            columnDef: 'leadVolume',
            isMom: false,
            isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Total Sales Leads',
            columnDef: 'leadVolumePreviousMTD',
            isMom: true,
            isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Sales Leads MOM',
            columnDef: 'leadVolumeMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Total Sales Leads',
            columnDef: 'leadVolumePreviousYear',
            isMom: false,
            isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Sales Leads YOY',
            columnDef: 'leadVolumeYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Response Rate',
            columnDef: 'responseRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Response Rate',
            columnDef: 'responseRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate MOM',
            columnDef: 'responseRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Response Rate',
            columnDef: 'responseRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate YOY',
            columnDef: 'responseRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Response Rate < 30 Min',
            columnDef: 'responseRate30',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Response Rate < 30 Min',
            columnDef: 'responseRate30PreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate < 30 Min MOM',
            columnDef: 'responseRate30MOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Response Rate < 30 Min',
            columnDef: 'responseRate30PreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate < 30 Min YOY',
            columnDef: 'responseRate30YOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Response Rate < 45 Min',
            columnDef: 'responseRate45',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Response Rate < 45 Min',
            columnDef: 'responseRate45PreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate < 45 Min MOM',
            columnDef: 'responseRate45MOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Response Rate < 45 Min',
            columnDef: 'responseRate45PreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate < 45 Min YOY',
            columnDef: 'responseRate45YOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Response Rate < 1 Hr',
            columnDef: 'responseRate60',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Response Rate < 1 Hr',
            columnDef: 'responseRate60PreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate < 1 Hr MOM',
            columnDef: 'responseRate60MOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Response Rate < 1 Hr',
            columnDef: 'responseRate60PreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate < 1 Hr YOY',
            columnDef: 'responseRate60YOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Avg. Response Time (48 Hrs)',
            columnDef: 'averageResponseTime48Hour',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Avg. Response Time (48 Hrs)',
            columnDef: 'averageResponseTime48HourPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Avg. Response Time (48 Hrs) MOM',
            columnDef: 'averageResponseTime48HourMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Avg. Response Time (48 Hrs)',
            columnDef: 'averageResponseTime48HourPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Avg. Response Time (48 Hrs) YOY',
            columnDef: 'averageResponseTime48HourYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Overall Average Response Time',
            columnDef: 'averageResponseTime',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Overall Average Response Time',
            columnDef: 'averageResponseTimePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Overall Average Response Time MOM',
            columnDef: 'averageResponseTimeMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Overall Average Response Time',
            columnDef: 'averageResponseTimePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Overall Average Response Time YOY',
            columnDef: 'averageResponseTimeYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        // Lead Sales
        {
          show: true,
          header: 'Lead Sales',
          columnDef: 'bestMatchSales',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month Lead Sales',
          columnDef: 'bestMatchSalesPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Lead Sales MOM',
          columnDef: 'bestMatchSalesMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Lead Sales',
          columnDef: 'bestMatchSalesPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Lead Sales YOY',
          columnDef: 'bestMatchSalesYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        // Lost Opportunities
        {
          show: true,
          header: 'Lost Opportunities',
          columnDef: 'lostDealerSales',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month Lost Opportunities',
          columnDef: 'lostDealerSalesPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Lost Opportunities MOM',
          columnDef: 'lostDealerSalesMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Lost Opportunities',
          columnDef: 'lostDealerSalesPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Lost Opportunities YOY',
          columnDef: 'lostDealerSalesYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        // Close Rate
        {
          show: true,
          header: 'Close Rate',
          columnDef: 'bestMatchCloseRate',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month Close Rate',
          columnDef: 'bestMatchCloseRatePreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Close Rate MOM',
          columnDef: 'bestMatchCloseRateMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Close Rate',
          columnDef: 'bestMatchCloseRatePreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Close Rate YOY',
          columnDef: 'bestMatchCloseRateYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        // 30 Day Close Rate
        {
          show: true,
          header: '30-Day Close Rate',
          columnDef: 'bestMatchCloseRate30',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month 30-Day Close Rate',
          columnDef: 'bestMatchCloseRate30PreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: '30-Day Close Rate MOM',
          columnDef: 'bestMatchCloseRate30MOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year 30-Day Close Rate',
          columnDef: 'bestMatchCloseRate30PreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: '30-Day Close Rate YOY',
          columnDef: 'bestMatchCloseRate30YOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
          // 60 Day Close Rate
        {
          show: true,
          header: '60-Day Close Rate',
          columnDef: 'bestMatchCloseRate60',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month 60-Day Close Rate',
          columnDef: 'bestMatchCloseRate60PreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: '60-Day Close Rate MOM',
          columnDef: 'bestMatchCloseRate60MOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year 60-Day Close Rate',
          columnDef: 'bestMatchCloseRate60PreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: '60-Day Close Rate YOY',
          columnDef: 'bestMatchCloseRate60YOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
          // 90 Day Close Rate
        {
          show: true,
          header: '90-Day Close Rate',
          columnDef: 'bestMatchCloseRate90',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month 90-Day Close Rate',
          columnDef: 'bestMatchCloseRate90PreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: '90-Day Close Rate MOM',
          columnDef: 'bestMatchCloseRate90MOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year 90-Day Close Rate',
          columnDef: 'bestMatchCloseRate90PreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: '90-Day Close Rate YOY',
          columnDef: 'bestMatchCloseRate90YOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
          // 120 Day Close Rate
        {
          show: true,
          header: '120-Day Close Rate',
          columnDef: 'bestMatchCloseRate120',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Month 120-Day Close Rate',
          columnDef: 'bestMatchCloseRate120PreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: '120-Day Close Rate MOM',
          columnDef: 'bestMatchCloseRate120MOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year 120-Day Close Rate',
          columnDef: 'bestMatchCloseRate120PreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
          show: false,
          header: '120-Day Close Rate YOY',
          columnDef: 'bestMatchCloseRate120YOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },    {
          show: true,
          header: 'Appointment Set',
          columnDef: 'appointments',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
          show: false,
          header: 'Prev. Month Appointment Set',
          columnDef: 'appointmentsPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString,
          clickable: false
      },
      {
          show: false,
          header: 'Appointment Set MOM',
          columnDef: 'appointmentsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
      },
      {
          show: false,
          header: 'Prev. Year Appointment Set',
          columnDef: 'appointmentsPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
          show: false,
          header: 'Appointment Set YOY',
          columnDef: 'appointmentsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
      },
        {
            show: true,
            header: 'Appointment Rate',
            columnDef: 'appointmentRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Appointment Rate',
            columnDef: 'appointmentRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Appointment Rate MOM',
            columnDef: 'appointmentRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Appointment Rate',
            columnDef: 'appointmentRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Appointment Rate YOY',
            columnDef: 'appointmentRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: true,
            header: 'Avg. Communications',
            columnDef: 'averageCommunications',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.roundToTenth, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Avg. Communications',
            columnDef: 'averageCommunicationsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.roundToTenth, clickable: false
        },
        {
            show: false,
            header: 'Avg. Communications MOM',
            columnDef: 'averageCommunicationsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Avg. Communications',
            columnDef: 'averageCommunicationsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.roundToTenth, clickable: false
        },
        {
            show: false,
            header: 'Avg. Communications YOY',
            columnDef: 'averageCommunicationsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
    ];

    public trendTableMetrics: INameValuePair[] = [
        {name: 'Total Sales Leads', value: 'leadVolume'},
        {name: 'Response Rate', value: 'responseRate'},
        {name: 'Response Rate < 1 Hr', value: 'responseRate60'},
        {name: 'Avg. Response Time (48 Hr)', value: 'averageResponseTime48Hour'},
        {name: 'Overall Avg. Response Time', value: 'averageResponseTime'},
        {name: 'Lead Sales', value: 'bestMatchSales'},
        {name: 'Lost Opportunities', value: 'lostDealerSales'},
        {name: 'Close Rate', value: 'bestMatchCloseRate'},
        {name: '30-Day Close Rate', value: 'bestMatchCloseRate30'},
        {name: '60-Day Close Rate', value: 'bestMatchCloseRate60'},
        {name: '90-Day Close Rate', value: 'bestMatchCloseRate90'},
        {name: '120-Day Close Rate', value: 'bestMatchCloseRate120'},
        {name: 'Appointment Set', value: 'appointments'},
        {name: 'Appointment Rate', value: 'appointmentRate'},
        {name: 'Avg. Communications', value: 'averageCommunications'},
    ];
}
