import {
  IDigAdOverviewBillboardConfig,
  IDigAdOverviewConfig,
  IDefaultTableColumnConfig,
  IDigAdOverviewKpiConfig,
  ICardHeaderConfig,
  IDigAdOverviewChannelCardConfig,
  IDigAdOverviewTrafficTypeCardConfig
} from '../../../../_shared/models/models-index';

import * as constants from '../../../../_shared/constants/constants';
export class DigAdOverviewConfig implements IDigAdOverviewConfig {

  public reportTitle: string = 'Digital Advertising Overview';
  public chartSeriesColors = ['#020B24', '#B0A591', '#A6A6A6', '#D9D9D9', '#CCCCCC'];

  public providerPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Provider Performance',
    iconClass: 'fa-tasks',
    helpTextKey: constants.helpTextKeys.digAdOverviewProviderPerformanceHelp,
    helpTextTitle: 'Provider Performance',
  };
  public dealerPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Retailer Performance',
    iconClass: 'fa-suitcase',
    helpTextKey: constants.helpTextKeys.digAdOverviewDealerPerformanceHelp,
    helpTextTitle: 'Retailer Performance',
  };
  public trafficTrendCardHeader: ICardHeaderConfig = {
    title: 'Traffic Trend',
    iconClass: 'fa-bar-chart',
    helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTrendHelp,
    helpTextTitle: 'Traffic Trend',
  };
  public channelPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Channel Performance',
    iconClass: 'fa-adjust',
    helpTextKey: constants.helpTextKeys.digAdOverviewChannelPerformanceHelp,
    helpTextTitle: 'Channel Performance',
  };
  public trafficTypeCardHeader: ICardHeaderConfig = {
    title: 'Organic vs Paid',
    iconClass: 'fa-adjust',
    helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTypeHelp,
    helpTextTitle: 'Traffic Type',
  };

  public channelCardConfig: IDigAdOverviewChannelCardConfig = {
    channelColors: ['#c51230', '#9e2a75', '#5f1675', '#6b45cc', '#0f4ec4', '#266886', '#369e8d', '#209645', '#587e35', '#ccc145', '#cc8b45', '#666666'],
    dimensionPropertyName: 'displayName',
    primaryMetricPropertyName: 'visits',
    secondaryMetricPropertyName: 'visitsWithEngagements'
  };

  public trafficTypeCardConfig: IDigAdOverviewTrafficTypeCardConfig = {
    headerConfig: {
      title: 'Organic vs Paid',
      iconClass: 'fa-adjust',
      helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTypeHelp,
      helpTextTitle: 'Traffic Type',
    },
    chartConfig: {
      colors: ['#020B24', '#B0A591'],
      paidPropertyName: 'visits',
      organicPropertyName: 'organicWebsiteVisits'
    }
  }

  public billboards: IDigAdOverviewBillboardConfig[] = [
    {
      title: 'Click Through Rate',
      subtitle: null,
      subtitleOne: 'clickThroughRateMTD',
      subtitleTwo: 'clickThroughRatePreviousMonth',
      iconClass: 'fa-eye fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'clickThroughRate',
      metricMOMPropertyName: 'clickThroughRateMOM',
      metricYOYPropertyName: 'clickThroughRateYOY',
      metricPreviousMonthPropertyName: 'clickThroughRatePreviousMonth',
      metricPreviousMTDPropertyName: 'clickThroughRatePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'clickThroughRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'DigAdOverviewClickThroughRateBillboardHelp',
      helpTextTitle: 'Click Through Rate',
      showTrendArrow: true
    },
    {
      title: 'Total Visits',
      subtitle: null,
      subtitleOne: 'visitsPreviousMTD',
      subtitleTwo: 'visitsPreviousMonth',
      iconClass: 'fa-users fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'visits',
      metricMOMPropertyName: 'visitsMOM',
      metricYOYPropertyName: 'visitsYOY',
      metricPreviousMonthPropertyName: 'visitsPreviousMonth',
      metricPreviousMTDPropertyName: 'visitsPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'visitsMOM',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      helpTextKey: 'DigAdOverviewTotalVisitsBillboardHelp',
      helpTextTitle: 'Total Visits',
      showTrendArrow: true
    },
    {
      title: 'Qualified Visits',
      subtitle: null,
      subtitleOne: 'qualifiedVisitsPreviousMTD',
      subtitleTwo: 'qualifiedVisitsPreviousMonth',
      iconClass: 'fa-user-plus fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'qualifiedVisits',
      metricMOMPropertyName: 'qualifiedVisitsMOM',
      metricYOYPropertyName: 'qualifiedVisitsYOY',
      metricPreviousMonthPropertyName: 'qualifiedVisitsPreviousMonth',
      metricPreviousMTDPropertyName: 'qualifiedVisitsPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'qualifiedVisitsMOM',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      helpTextKey: 'DigAdOverviewQualifiedVisitsBillboardHelp',
      helpTextTitle: 'Qualified Visits',
      showTrendArrow: true
    },
    {
      title: 'Action Rate',
      subtitle: null,
      subtitleOne: 'actionRatePreviousMTD',
      subtitleTwo: 'actionRatePreviousMonth',
      iconClass: 'fa-mouse-pointer fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'actionRate',
      metricMOMPropertyName: 'actionRateMOM',
      metricYOYPropertyName: 'actionRateYOY',
      metricPreviousMonthPropertyName: 'actionRatePreviousMonth',
      metricPreviousMTDPropertyName: 'actionRatePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'actionRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'DigAdOverviewActionRateBillboardHelp',
      helpTextTitle: 'Action Rate',
      showTrendArrow: true
    },
  ];

  public kpiSheetOptions = {
    overview: [{
      name: 'displayName',
      label: '',
      isHeader: true
    }, {
      name: 'visits',
      label: 'Visits',
      format: constants.formatKeys.localeStringOrNa
    }, {
      name: 'vdpViews',
      label: 'VDPs',
      format: constants.formatKeys.localeStringOrNa
    }, {
      name: 'engagementRate',
      label: 'Engagement Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'bounceRate',
      label: 'Bounce Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'salesServiceOtherBounces',
      label: 'Sales | Service | Other | Bounces',
    }]
  };

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Retailer Name',
      columnDef: 'displayName',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    // Impressions
    {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Clicks
    {
      show: true,
      header: 'Clicks',
      columnDef: 'clicks',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Click Through Rate
    {
      show: true,
      header: 'Click Through Rate',
      columnDef: 'clickThroughRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Total Visits
    {
      show: true,
      header: 'Total Visits',
      columnDef: 'visits',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Unique Visits
    {
      show: true,
      header: 'Unique Visits',
      columnDef: 'uniqueVisitors',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Qualified Visits
    {
      show: true,
      header: 'Qualified Visits',
      columnDef: 'qualifiedVisits',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Form Submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Phone Calls
    {
      show: true,
      header: 'Phone Calls',
      columnDef: 'phoneLeads',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Action Rate
    {
      show: true,
      header: 'Action Rate',
      columnDef: 'actionRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Engagement Rate
    {
      show: true,
      header: 'Engagement Rate',
      columnDef: 'engagementRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // VLP Views
    {
      show: true,
      header: 'VLP Views',
      columnDef: 'vlpViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // VDP Views
    {
      show: true,
      header: 'VDP Views',
      columnDef: 'vdpViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Bounces
    {
      show: true,
      header: 'Bounces',
      columnDef: 'bounces',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Bounce Rate
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Midpoint Views
    {
      show: true,
      header: 'Midpoint Views',
      columnDef: 'midpointViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Video Completions
    {
      show: true,
      header: 'Completions',
      columnDef: 'completions',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Video Completion Rate
    {
      show: true,
      header: 'Completion Rate',
      columnDef: 'completionRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Cost per Impressions
    {
      show: true,
      header: 'Cost Per Impression',
      columnDef: 'costPerImpression',
      metricFormatKey: constants.formatKeys.currency,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Cost per Click
    {
      show: true,
      header: 'Cost Per Click',
      columnDef: 'costPerClick',
      metricFormatKey: constants.formatKeys.currency,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Cost per Action
    {
      show: true,
      header: 'Cost Per Action',
      columnDef: 'costPerAction',
      metricFormatKey: constants.formatKeys.currency,
      clickable: false,
      hasComparisonMetrics: true
    },

  ];
  public dealerBenchmarkLookbackColumns?: IDefaultTableColumnConfig[] = [];
  public kpiCardConfig: IDigAdOverviewKpiConfig = {
    chartTitle: 'Total Visits vs. Engaged Visits',
    preCutoffChartTitle: 'Total Visits vs. Unique Visits',
    xAxisPropertyName: 'date',
    metrics: {
      'MetricOne': {
          yAxisTitle: 'Visits',
          displayName: 'Total Visits',
          pointLabelFormat: null,
          propertyName: 'visits',
          metricFormatKey: null,
          formatter: null
      },
      'MetricTwo': {
          yAxisTitle: 'Visits',
          displayName: 'Engaged Visits',
          pointLabelFormat: null,
          propertyName: 'visitsWithEngagements',
          metricFormatKey: null,
          formatter: null
      },
      'PreCutoffMetricTwo': {
          yAxisTitle: 'Visits',
          displayName: 'Unique Visits',
          pointLabelFormat: null,
          propertyName: 'uniqueVisitors',
          metricFormatKey: null,
          formatter: null
      },
      'PreCutoffMetricOne': {
        yAxisTitle: 'Visits',
        displayName: 'Total Visits',
        pointLabelFormat: null,
        propertyName: 'visits',
        metricFormatKey: null,
        formatter: null
      }
    }
  };
}
