import {
  IDefaultTableColumnConfig,
  IDigAdProviderConfig,
  IMultiLevelDualAxisLineGraphConfig,
  ICardHeaderConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdProviderConfig implements IDigAdProviderConfig {
  reportTitle: string = 'Digital Advertising Provider Performance';
  public useDefaultComparisonColumns: boolean = true;
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Summary Data',
    iconClass: 'fa-file',
    helpTextKey: constants.helpTextKeys.digAdProviderSummaryHelp,
    helpTextTitle: 'Provider Summary',
    exportName: 'Digital Advertising Provider Performance - Summary Data',
    metricDisplayModes: ['MOM', 'YOY']
  };

  public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      // Impressions
      {
        id: Enums.digitalAdvertisingMetrics.impressions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.impressions.name,
        propertyName: Enums.digitalAdvertisingMetrics.impressions.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Clicks
      {
        id: Enums.digitalAdvertisingMetrics.clicks.metricId,
        displayName: Enums.digitalAdvertisingMetrics.clicks.name,
        propertyName: Enums.digitalAdvertisingMetrics.clicks.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Click Through Rate
      {
        id: Enums.digitalAdvertisingMetrics.clickThroughRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.clickThroughRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.clickThroughRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Total Visits
      {
        id: Enums.digitalAdvertisingMetrics.totalVisits.metricId,
        displayName: Enums.digitalAdvertisingMetrics.totalVisits.name,
        propertyName: 'visits',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Unique Visits
      {
        id: Enums.digitalAdvertisingMetrics.uniqueVisitors.metricId,
        displayName: Enums.digitalAdvertisingMetrics.uniqueVisitors.name,
        propertyName: Enums.digitalAdvertisingMetrics.uniqueVisitors.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Qualified Visits
      {
        id: Enums.digitalAdvertisingMetrics.qualifiedVisits.metricId,
        displayName: Enums.digitalAdvertisingMetrics.qualifiedVisits.name,
        propertyName: Enums.digitalAdvertisingMetrics.qualifiedVisits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Form Submissions
      {
        id: Enums.digitalAdvertisingMetrics.formSubmissions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.formSubmissions.name,
        propertyName: Enums.digitalAdvertisingMetrics.formSubmissions.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Phone Calls
      {
        id: Enums.digitalAdvertisingMetrics.phoneCalls.metricId,
        displayName: Enums.digitalAdvertisingMetrics.phoneCalls.name,
        propertyName: 'phoneLeads',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Action Rate
      {
        id: Enums.digitalAdvertisingMetrics.actionRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actionRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.actionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Engagement Rate
      {
        id: Enums.digitalAdvertisingMetrics.engagementRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.engagementRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.engagementRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // VLP Views
      {
        id: Enums.digitalAdvertisingMetrics.vlpViews.metricId,
        displayName: Enums.digitalAdvertisingMetrics.vlpViews.name,
        propertyName: 'vlpViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      // VDP Views
      {
        id: Enums.digitalAdvertisingMetrics.vdpViews.metricId,
        displayName: Enums.digitalAdvertisingMetrics.vdpViews.name,
        propertyName: 'vdpViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Bounces
      {
        id: Enums.digitalAdvertisingMetrics.bounces.metricId,
        displayName: Enums.digitalAdvertisingMetrics.bounces.name,
        propertyName: Enums.digitalAdvertisingMetrics.bounces.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Bounce Rate
      {
        id: Enums.digitalAdvertisingMetrics.bounceRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.bounceRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.bounceRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Midpoint Views
      {
        id: Enums.digitalAdvertisingMetrics.midpointViews.metricId,
        displayName: Enums.digitalAdvertisingMetrics.midpointViews.name,
        propertyName: Enums.digitalAdvertisingMetrics.midpointViews.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Completions
      {
        id: Enums.digitalAdvertisingMetrics.completions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.completions.name,
        propertyName: Enums.digitalAdvertisingMetrics.completions.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Completion Rate
      {
        id: Enums.digitalAdvertisingMetrics.completionRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.completionRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.completionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Cost Per Impression
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerImpression.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerImpression.name,
        propertyName: 'costPerImpression',
        metricFormatKey: constants.formatKeys.currency
      },
      // Cost Per Click
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerClick.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerClick.name,
        propertyName: 'costPerClick',
        metricFormatKey: constants.formatKeys.currency
      },
      // Cost Per Action
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerAction.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerAction.name,
        propertyName: 'costPerAction',
        metricFormatKey: constants.formatKeys.currency
      },
    ],
    childMetrics: [
      {
        id: Enums.digitalAdvertisingProviders.allProviders.providerId,
        displayName: Enums.digitalAdvertisingProviders.allProviders.name,
        propertyName: Enums.digitalAdvertisingProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 3,
        displayName: 'DealerOn',
        propertyName: 'DealerOn',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 5,
        displayName: 'Ansira',
        propertyName: 'Ansira',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 11,
        displayName: 'SoKal',
        propertyName: 'SoKal',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 14,
        displayName: 'Fox Dealer',
        propertyName: 'Fox Dealer',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 21,
        displayName: 'Constellation',
        propertyName: 'Constellation',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 22,
        displayName: 'Stream',
        propertyName: 'Stream',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 23,
        displayName: 'Force Marketing',
        propertyName: 'Force Marketing',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
    ],

    defaultChildrenIds: [Enums.digitalAdvertisingProviders.allProviders.providerId, Enums.digitalAdvertisingProviders.allProviders.providerId],
    defaultParentIds: [Enums.digitalAdvertisingMetrics.impressions.metricId, Enums.digitalAdvertisingMetrics.clicks.metricId],
    metricOneColorHexCode: '#D0573a',
    metricTwoColorHexCode: '#646464'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: 'Provider',
      columnDef: 'entity',
      hasComparisonMetrics: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Clicks',
      columnDef: 'clicks',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Click Through Rate',
      columnDef: 'clickThroughRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'Total Visits',
      columnDef: 'visits',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Unique Visits',
      columnDef: 'uniqueVisitors',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Qualified Visits',
      columnDef: 'qualifiedVisits',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Phone Calls',
      columnDef: 'phoneLeads',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Action Rate',
      columnDef: 'actionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'Engagement Rate',
      columnDef: 'engagementRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'VLP Views',
      columnDef: 'vlpViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'VDP Views',
      columnDef: 'vdpViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Bounces',
      columnDef: 'bounces',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'Midpoint Views',
      columnDef: 'midpointViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Completions',
      columnDef: 'completions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Completion Rate',
      columnDef: 'completionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'Cost Per Impressions',
      columnDef: 'costPerImpression',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: true,
      header: 'Cost Per Click',
      columnDef: 'costPerClick',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: true,
      header: 'Cost Per Action',
      columnDef: 'costPerAction',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.currency
    },
  ];

}
