import {
    IDigAdScorecardConfig, IDigAdScorecardCategoryConfig } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdScorecardConfig implements IDigAdScorecardConfig {
    public reportTitle = 'Digital Advertising Scorecard';
    public scorecards: IDigAdScorecardCategoryConfig[] = [
        {
            category: 'Awareness',
            billboards: [
                {
                    title: 'Impressions',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'impressions',
                    metricMOMPropertyName: 'impressionsMOM',
                    metricYOYPropertyName: 'impressionsYOY',
                    metricPreviousMonthPropertyName: 'impressionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'impressionsPreviousMTD',
                    metricPreviousYearPropertyName: 'impressionsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'impressionsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardImpressionsHelp',
                    helpTextTitle: 'Impressions',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Clicks',
                    subtitle: '',
                    iconClass: 'fa-mouse-pointer fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clicks',
                    metricMOMPropertyName: 'clicksMOM',
                    metricYOYPropertyName: 'clicksYOY',
                    metricPreviousMonthPropertyName: 'clicksPreviousMonth',
                    metricPreviousMTDPropertyName: 'clicksPreviousMTD',
                    metricPreviousYearPropertyName: 'clicksPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clicksMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardClicksHelp',
                    helpTextTitle: 'Clicks',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Click Through Rate',
                    subtitle: '',
                    iconClass: 'fa-mouse-pointer fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clickThroughRate',
                    metricMOMPropertyName: 'clickThroughRateMOM',
                    metricYOYPropertyName: 'clickThroughRateYOY',
                    metricPreviousMonthPropertyName: 'clickThroughRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'clickThroughRatePreviousMTD',
                    metricPreviousYearPropertyName: 'clickThroughRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    subtitleMetricFormatKey: constants.formatKeys.percentageNoDecimals,
                    trendArrowDrivingPropertyName: 'clickThroughRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardClickThroughRateHelp',
                    helpTextTitle: 'Click Through Rate',
                    scoreCardCategory: 'Awareness'
                },
            ],
        },
        {
            category: 'Traffic',
            billboards: [
                {
                    title: 'Visits',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'visits',
                    metricMOMPropertyName: 'visitsMOM',
                    metricYOYPropertyName: 'visitsYOY',
                    metricPreviousMonthPropertyName: 'visitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'visitsPreviousMTD',
                    metricPreviousYearPropertyName: 'visitsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'visitsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardVisitsHelp',
                    helpTextTitle: 'Visits',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Unique Visits',
                    subtitle: '',
                    iconClass: 'fa-user fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'uniqueVisitors',
                    metricMOMPropertyName: 'uniqueVisitorsMOM',
                    metricYOYPropertyName: 'uniqueVisitorsYOY',
                    metricPreviousMonthPropertyName: 'uniqueVisitorsPreviousMonth',
                    metricPreviousMTDPropertyName: 'uniqueVisitorsPreviousMTD',
                    metricPreviousYearPropertyName: 'uniqueVisitorsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'uniqueVisitorsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardUniqueVisitsHelp',
                    helpTextTitle: 'Unique Visits',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Qualified Visits',
                    subtitle: '',
                    iconClass: 'fa-user fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'qualifiedVisits',
                    metricMOMPropertyName: 'qualifiedVisitsMOM',
                    metricYOYPropertyName: 'qualifiedVisitsYOY',
                    metricPreviousMonthPropertyName: 'qualifiedVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'qualifiedVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'qualifiedVisitsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'qualifiedVisitsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardQualifiedVisitsHelp',
                    helpTextTitle: 'Qualified Visits',
                    scoreCardCategory: 'Traffic'
                },
            ]
        },
        {
            category: 'Engagement',
            billboards: [
                {
                    title: 'Time on Site',
                    subtitle: '',
                    iconClass: 'fa-clock-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'averageTimeOnSite',
                    metricMOMPropertyName: 'averageTimeOnSiteMOM',
                    metricYOYPropertyName: 'averageTimeOnSiteYOY',
                    metricPreviousMonthPropertyName: 'averageTimeOnSitePreviousMonth',
                    metricPreviousMTDPropertyName: 'averageTimeOnSitePreviousMTD',
                    metricPreviousYearPropertyName: 'averageTimeOnSitePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'averageTimeOnSiteMOM',
                    metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
                    helpTextKey: 'DigAdScorecardTimeOnSiteHelp',
                    helpTextTitle: 'Time On Site',
                    scoreCardCategory: 'Engagement'
                },
                {
                    title: 'Bounce Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'bounceRate',
                    metricMOMPropertyName: 'bounceRateMOM',
                    metricYOYPropertyName: 'bounceRateYOY',
                    metricPreviousMonthPropertyName: 'bounceRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'bounceRatePreviousMTD',
                    metricPreviousYearPropertyName: 'bounceRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    subtitleMetricFormatKey: constants.formatKeys.percentageNoDecimals,
                    trendArrowDrivingPropertyName: 'bounceRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardBounceRatesHelp',
                    helpTextTitle: 'Bounce Rate',
                    scoreCardCategory: 'Engagement'
                },
                {
                    title: 'Engagement Rate',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'engagementRate',
                    metricMOMPropertyName: 'engagementRateMOM',
                    metricYOYPropertyName: 'engagementRateYOY',
                    metricPreviousMonthPropertyName: 'engagementRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'engagementRatePreviousMTD',
                    metricPreviousYearPropertyName: 'engagementRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    subtitleMetricFormatKey: constants.formatKeys.percentageNoDecimals,
                    trendArrowDrivingPropertyName: 'engagementRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardEngagementRatesHelp',
                    helpTextTitle: 'Engagement Rate',
                    scoreCardCategory: 'Engagement'
                },
            ]
        },
        {
            category: 'Actions',
            billboards: [
                {
                    title: 'Form Submissions',
                    subtitle: '',
                    iconClass: 'fa-file-text fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'formSubmissions',
                    metricMOMPropertyName: 'formSubmissionsMOM',
                    metricYOYPropertyName: 'formSubmissionsYOY',
                    metricPreviousMonthPropertyName: 'formSubmissionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'formSubmissionsPreviousMTD',
                    metricPreviousYearPropertyName: 'formSubmissionsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'formSubmissionsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardFormSubmissionsHelp',
                    helpTextTitle: 'Form Submissions',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Phone Calls',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'phoneLeads',
                    metricMOMPropertyName: 'phoneLeadsMOM',
                    metricYOYPropertyName: 'phoneLeadsYOY',
                    metricPreviousMonthPropertyName: 'phoneLeadsPreviousMonth',
                    metricPreviousMTDPropertyName: 'phoneLeadsPreviousMTD',
                    metricPreviousYearPropertyName: 'phoneLeadsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'phoneLeadsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardPhoneCallsHelp',
                    helpTextTitle: 'Phone Calls',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Action Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'actionRate',
                    metricMOMPropertyName: 'actionRateMOM',
                    metricYOYPropertyName: 'actionRateYOY',
                    metricPreviousMonthPropertyName: 'actionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'actionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'actionRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    subtitleMetricFormatKey: constants.formatKeys.percentageNoDecimals,
                    trendArrowDrivingPropertyName: 'actionRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardActionRateHelp',
                    helpTextTitle: 'Action Rate',
                    scoreCardCategory: 'Actions'
                },
            ]
        }
    ];
}
