import { IDefaultTableColumnConfig, ILeadsVehicleConfig, IMultiLevelDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsVehicleConfig implements ILeadsVehicleConfig {

    metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' }
    ];

    reportTitle = 'Vehicle Performance'
    useDefaultComparisonColumns = true;

    public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
            // Lead Volume
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: Enums.leadMetrics.leadVolume.name,
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Lead Sales
            {
                id: Enums.leadMetrics.leadSales.metricId,
                displayName: Enums.leadMetrics.leadSales.name,
                propertyName: 'bestMatchSales',
                metricFormatKey: constants.formatKeys.localeString
            },
            // Gained Sales
            {
                id: Enums.leadMetrics.gainedSales.metricId,
                displayName: Enums.leadMetrics.gainedSales.name,
                propertyName: Enums.leadMetrics.gainedSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Lost Sales
            {
                id: Enums.leadMetrics.lostSales.metricId,
                displayName: Enums.leadMetrics.lostSales.name,
                propertyName: 'lostVehicleSales',
                metricFormatKey: constants.formatKeys.localeString
            },
            // Percent of Leads
            {
                id: Enums.leadMetrics.percentOfLeads.metricId,
                displayName: Enums.leadMetrics.percentOfLeads.name,
                propertyName: 'percentOfLeadVolume',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Percent of Sales
            {
                id: Enums.leadMetrics.percentOfSales.metricId,
                displayName: Enums.leadMetrics.percentOfSales.name,
                propertyName: 'percentOfBestMatchSales',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],
        childMetrics: [], // this needs to be data driven

        defaultChildrenIds: [ 'All Models', 'All Models' ],
        defaultParentIds: [Enums.leadMetrics.leadVolume.metricId, Enums.leadMetrics.leadSales.metricId],
        metricOneColorHexCode: '#D0573a',
        metricTwoColorHexCode: '#646464'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Model',
            columnDef: 'entity',
            hasComparisonMetrics: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Lead Volume
        {
            show: true,
            header: 'Lead Volume',
            columnDef: 'leadVolume',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
        // Lead Sales
        {
            show: true,
            header: 'Lead Sales',
            columnDef: 'bestMatchSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
        // Gained Sales
        {
            show: true,
            header: 'Gained Sales',
            columnDef: 'gainedSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
        // Lost Sales
        {
            show: true,
            header: 'Lost Sales',
            columnDef: 'lostVehicleSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
        // Percent of Leads
        {
            show: true,
            header: '% of Leads',
            columnDef: 'percentOfLeadVolume',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
        // Percent of Sales
        {
            show: true,
            header: '% of Sales',
            columnDef: 'percentOfBestMatchSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
    ];
}
