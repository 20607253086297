import {
    IChatOverviewConfig,
    IChatOverviewBillboardConfig,
    ICardHeaderConfig,
    IMultiLevelDualAxisLineGraphConfig,
    IDefaultTableColumnConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class ChatOverviewConfig implements IChatOverviewConfig {
    public reportTitle = 'Chat Overview';
    public hasChatHelpText: false;
    public metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' }
    ];
    public billboards: IChatOverviewBillboardConfig[] = [
        {
            title: 'Chats Started',
            subtitle: '',
            iconClass: 'fa-comments fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'starts',
            metricMOMPropertyName: 'startsMOM',
            metricYOYPropertyName: 'startsYOY',
            metricPreviousMonthPropertyName: 'startsPreviousMonth',
            metricPreviousMTDPropertyName: 'startsPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            showTrendArrow: true,
            trendArrowDrivingPropertyName: 'startsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: 'ChatOverviewChatsStartedHelp',
            helpTextTitle: 'Chats Started'
        },
        {
            title: 'Engagement Rate',
            subtitle: '',
            iconClass: 'fa-percent fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'engagementRate',
            metricMOMPropertyName: 'engagementRateMOM',
            metricYOYPropertyName: 'engagementRateYOY',
            metricPreviousMonthPropertyName: 'engagementRatePreviousMonth',
            metricPreviousMTDPropertyName: 'engagementRatePreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            showTrendArrow: true,
            trendArrowDrivingPropertyName: 'engagementRateMOM',
            metricFormatKey: constants.formatKeys.percentageOneDecimal,
            helpTextKey: 'ChatOverviewChatEngagementRateHelp',
            helpTextTitle: 'Chat Engagement Rate'
      },
      {
          title: 'Chat Leads',
          subtitle: '',
          iconClass: 'fa-users fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'leadVolume',
          metricMOMPropertyName: 'leadVolumeMOM',
          metricYOYPropertyName: 'leadVolumeYOY',
          metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
          metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
          metricPreviousMTDDisplayOnCurrentMonth: true,
          metricPreviousMTDDisplayHistorical: false,
          metricPreviousMTDLabel: 'Prev. MTD',
          metricPreviousMonthDisplayOnCurrentMonth: true,
          metricPreviousMonthDisplayHistorical: true,
          metricPreviousMonthLabel: 'Prev. Month',
          metricPreviousYearPropertyName: null,
          footerRowCount: 1,
          footerTwoLeftLabel: 'MOM',
          footerTwoRightLabel: 'YOY',
          footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          showTrendArrow: true,
          trendArrowDrivingPropertyName: 'leadVolumeMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: 'ChatOverviewChatLeadsHelp',
          helpTextTitle: 'Chat Leads'
      },
      {
          title: 'Chat Conversions',
          subtitle: '',
          iconClass: 'fa-percent fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'conversionRate',
          metricMOMPropertyName: 'conversionRateMOM',
          metricYOYPropertyName: 'conversionRateYOY',
          metricPreviousMonthPropertyName: 'conversionRatePreviousMonth',
          metricPreviousMTDPropertyName: 'conversionRatePreviousMTD',
          metricPreviousMTDDisplayOnCurrentMonth: true,
          metricPreviousMTDDisplayHistorical: false,
          metricPreviousMTDLabel: 'Prev. MTD',
          metricPreviousMonthDisplayOnCurrentMonth: true,
          metricPreviousMonthDisplayHistorical: true,
          metricPreviousMonthLabel: 'Prev. Month',
          metricPreviousYearPropertyName: null,
          footerRowCount: 1,
          footerTwoLeftLabel: 'MOM',
          footerTwoRightLabel: 'YOY',
          footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          showTrendArrow: true,
          trendArrowDrivingPropertyName: 'conversionRateMOM',
          metricFormatKey: constants.formatKeys.percentageOneDecimal,
          helpTextKey: 'ChatOverviewChatConversionsHelp',
          helpTextTitle: 'Chat Conversions'
      },
    ];

    public kpiSheetOptions = {
        overview: [{
            name: 'providerId',
            label: 'Provider',
            isHeader: true
        },
        {
            name: 'starts',
            label: 'Chats Started',
            format: constants.formatKeys.localeString
        },
        {
            name: 'startRate',
            label: 'Start Rate',
            format: constants.formatKeys.percentageTwoDecimals
        },
        {
            name: 'engagementRate',
            label: 'Engagement Rate',
            format: constants.formatKeys.percentageTwoDecimals
        },
        {
            name: 'abandonRate',
            label: 'Abandon Rate',
            format: constants.formatKeys.percentageTwoDecimals
        },
        {
            name: 'messagesPerChat',
            label: 'Average Messages/Chat',
            format: constants.formatKeys.roundToTenth
        },
        {
            name: 'conversionRate',
            label: 'Chat Lead Conversion Rate',
            format: constants.formatKeys.percentageTwoDecimals
        },
      ]
      };

    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.chatSummaryHelp,
        helpTextTitle: 'Chat Summary',
        exportName: 'Chat Summary - Summary Data',
        metricDisplayModes: ['MOM', 'YOY']
    };

    public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [

            {
                id: Enums.chatMetrics.impressions.metricId,
                displayName: Enums.chatMetrics.impressions.name,
                propertyName: Enums.chatMetrics.impressions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.chatMetrics.visitsWithImpressions.metricId,
                displayName: Enums.chatMetrics.visitsWithImpressions.name,
                propertyName: Enums.chatMetrics.visitsWithImpressions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.chatMetrics.chatsStarted.metricId,
                displayName: Enums.chatMetrics.chatsStarted.name,
                propertyName: Enums.chatMetrics.chatsStarted.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.chatMetrics.chatStartRate.metricId,
                displayName: Enums.chatMetrics.chatStartRate.name,
                propertyName: Enums.chatMetrics.chatStartRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Engagements
            {
              id: Enums.chatMetrics.chatsEngaged.metricId,
              displayName: Enums.chatMetrics.chatsEngaged.name,
              propertyName: Enums.chatMetrics.chatsEngaged.nameKey,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Engagement Rate
            {
              id: Enums.chatMetrics.chatEngagementRate.metricId,
              displayName: Enums.chatMetrics.chatEngagementRate.name,
              propertyName: Enums.chatMetrics.chatEngagementRate.nameKey,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.chatMetrics.chatsAbandoned.metricId,
                displayName: Enums.chatMetrics.chatsAbandoned.name,
                propertyName: Enums.chatMetrics.chatsAbandoned.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.chatMetrics.chatAbandonRate.metricId,
                displayName: Enums.chatMetrics.chatAbandonRate.name,
                propertyName: Enums.chatMetrics.chatAbandonRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.chatMetrics.avgMessagesPerChat.metricId,
                displayName: Enums.chatMetrics.avgMessagesPerChat.name,
                propertyName: Enums.chatMetrics.avgMessagesPerChat.nameKey,
                metricFormatKey: constants.formatKeys.roundToHundreth
            },
            {
              id: Enums.chatMetrics.chatLeads.metricId,
              displayName: Enums.chatMetrics.chatLeads.name,
              propertyName: Enums.chatMetrics.chatLeads.nameKey,
              metricFormatKey: constants.formatKeys.localeString
            },
            {
              id: Enums.chatMetrics.chatConversionRate.metricId,
              displayName: Enums.chatMetrics.chatConversionRate.name,
              propertyName: Enums.chatMetrics.chatConversionRate.nameKey,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],
        childMetrics: [
            {
                id: Enums.chatProviders.allProviders.providerId,
                displayName: Enums.chatProviders.allProviders.displayName,
                propertyName: Enums.chatProviders.allProviders.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 18,
                displayName: Enums.chatProviders.activEngage.displayName,
                propertyName: Enums.chatProviders.activEngage.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 15,
                displayName: Enums.chatProviders.carNow.displayName,
                propertyName: Enums.chatProviders.carNow.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 12,
                displayName: Enums.chatProviders.dealerInspire.displayName,
                propertyName: Enums.chatProviders.dealerInspire.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 19,
                displayName: Enums.chatProviders.gubagoo.displayName,
                propertyName: Enums.chatProviders.gubagoo.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 20,
                displayName: Enums.chatProviders.podium.displayName,
                propertyName: Enums.chatProviders.podium.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
        ],
        defaultChildrenIds: [ Enums.chatProviders.allProviders.providerId, Enums.chatProviders.allProviders.providerId ],
        defaultParentIds: [Enums.chatMetrics.impressions.metricId, Enums.chatMetrics.chatsStarted.metricId],
        metricOneColorHexCode: '#D0573a',
        metricTwoColorHexCode: '#646464'
    };
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: false,
            print: true,
            header: 'Retailer Name',
            columnDef: 'displayName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
        },
        {
            show: true,
            header: 'Chat Impressions',
            columnDef: 'impressions', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Month Chat Impressions',
            columnDef: 'impressionsPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chat Impressions MOM',
            columnDef: 'impressionsMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Chat Impressions',
            columnDef: 'impressionsPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chat Impressions YOY',
            columnDef: 'impressionsYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },

        {
            show: true,
            header: 'Visits with Chat Impressions',
            columnDef: 'visitsWithImpressions', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Month Visits with Chat Impressions',
            columnDef: 'visitsWithImpressionsPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Visits with Chat Impressions MOM',
            columnDef: 'visitsWithImpressionsMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Visits with Chat Impressions',
            columnDef: 'visitsWithImpressionsPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Visits with Chat Impressions YOY',
            columnDef: 'visitsWithImpressionsYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },

        {
            show: true,
            header: 'Chats Started',
            columnDef: 'starts', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Month Chats Started',
            columnDef: 'startsPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chats Started MOM',
            columnDef: 'startsMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Chats Started',
            columnDef: 'startsPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chats Started YOY',
            columnDef: 'startsYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },

        {
            show: true,
            header: 'Start Rate',
            columnDef: 'startRate', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Month Start Rate',
            columnDef: 'startRatePreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Start Rate MOM',
            columnDef: 'startRateMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Start Rate',
            columnDef: 'startRatePreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Start Rate YOY',
            columnDef: 'startRateYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },

        {
            show: true,
            header: 'Chat Engagements',
            columnDef: 'engagements', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Month Chat Engagements',
            columnDef: 'engagementsPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chat Engagements MOM',
            columnDef: 'engagementsMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Chat Engagements',
            columnDef: 'engagementsPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Chat Engagements YOY',
            columnDef: 'engagementsYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },


        {
            show: true,
            header: 'Engagement Rate',
            columnDef: 'engagementRate', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Month Engagement Rate',
            columnDef: 'engagementRatePreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Engagement Rate MOM',
            columnDef: 'engagementRateMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Engagement Rate',
            columnDef: 'engagementRatePreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Engagement Rate YOY',
            columnDef: 'engagementRateYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },


        {
            show: true,
            header: 'Abandoned Chats',
            columnDef: 'abandons', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Month Abandoned Chats',
            columnDef: 'abandonsPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Abandoned Chats MOM',
            columnDef: 'abandonsMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Abandoned Chats',
            columnDef: 'abandonsPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Abandoned Chats YOY',
            columnDef: 'abandonsYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },

        {
            show: true,
            header: 'Chat Abandon Rate',
            columnDef: 'abandonRate', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Month Chat Abandon Rate',
            columnDef: 'abandonRatePreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Chat Abandon Rate MOM',
            columnDef: 'abandonRateMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Prev. Year Chat Abandon Rate',
            columnDef: 'abandonRatePreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },
        {
            show: false,
            header: 'Chat Abandon Rate YOY',
            columnDef: 'abandonRateYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false },

        {
            show: true,
            header: 'Avg. Messages Per Chat',
            columnDef: 'messagesPerChat', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.roundToTenth, clickable: false },
        {
            show: false,
            header: 'Prev. Month Avg. Messages Per Chat',
            columnDef: 'messagesPerChatPreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.roundToTenth, clickable: false },
        {
            show: false,
            header: 'Avg. Messages Per Chat MOM',
            columnDef: 'messagesPerChatMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false },
        {
            show: false,
            header: 'Prev. Year Avg. Messages Per Chat',
            columnDef: 'messagesPerChatPreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.roundToTenth, clickable: false },
        {
            show: false,
            header: 'Avg. Messages Per Chat YOY',
            columnDef: 'messagesPerChatYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false },

      {
        show: true,
        header: 'Chat Leads',
        columnDef: 'leadVolume', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: false,
        header: 'Prev. Month Chat Leads',
        columnDef: 'leadVolumePreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: false,
        header: 'Chat Leads MOM',
        columnDef: 'leadVolumeMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Prev. Year Chat Leads',
        columnDef: 'leadVolumePreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: false,
        header: 'Chat Leads YOY',
        columnDef: 'leadVolumeYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },

      {
        show: true,
        header: 'Chat Lead Conversion',
        columnDef: 'conversionRate', isMom: false, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Prev. Month Chat Lead Conversion',
        columnDef: 'conversionRatePreviousMTD', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Chat Lead Conversion MOM',
        columnDef: 'conversionRateMOM', isMom: true, isYoy: false, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Prev. Year Chat Lead Conversion',
        columnDef: 'conversionRatePreviousYear', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Chat Lead Conversion YOY',
        columnDef: 'conversionRateYOY', isMom: false, isYoy: true, metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },

    ];
}
