import { IDealerResourceCategoryConfig, IDealerResourcesConfig } from '../../../../_shared/models/models-index';

export class DealerResourcesConfig implements IDealerResourcesConfig {
  public reportTitle = 'Retailer Resources';
  public disclaimer = '';
  public resourceCategories: IDealerResourceCategoryConfig[] = [
    {
      category: 'INFINITI Retailer Digital Marketing Program',
      resourceConfig: [
        {
          label: 'IRDMP Program Overview',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_IRDMP_Program_Overview.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_IRDMP_Program_Overview.pdf'
        },
      ]
    },
    {
      category: 'E-Commerce',
      resourceConfig: [
        {
          label: 'E-Commerce Program Overview',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Program_Overview.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Program_Overview.pdf'
        },
        {
          label: 'Provider Capabilities',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Provider_Capabilities.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Provider_Capabilities.pdf'
        },
        {
          label: 'E-Commerce Customer Journey',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Customer_Journey.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Customer_Journey.pdf'
        },
        {
          label: 'E-Commerce Customer Journey – Payment Calculator',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Payment_Calculator.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Payment_Calculator.pdf'
        },
        {
          label: 'E-Commerce Customer Journey – Trade-In',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Trade-In.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Trade-In.pdf'
        },
        {
          label: 'E-Commerce Customer Journey: Soft Credit Pull',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Soft_Credit_Pull.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Soft_Credit_Pull.pdf'
        },
        {
          label: 'E-Commerce Customer Journey - Accessories',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Accessories.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Accessories.pdf'
        },
        {
          label: ' E-Commerce Customer Journey – F&I',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Finance_Insurance.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Finance_Insurance.pdf'
        },
        {
          label: 'E-Commerce Customer Journey – Instant Financing',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Instant_Financing.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Instant_Financing.pdf'
        },
        {
          label: 'E-Commerce Customer Journey – E-Contracting & E-Signature',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_E-Contracting.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_E-Contracting.pdf'
        },
        {
          label: 'E-Commerce Customer Journey – Deposit',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Deposit.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Deposit.pdf'
        },
        {
          label: 'E-Commerce Customer Journey – Appointment Scheduling',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_Appointment_Scheduling.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_Appointment_Scheduling.pdf'
        },
        {
          label: 'Retailer E-Commerce Best Practices',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_Retailer_E-Commerce_Best_Practices.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_Retailer_E-Commerce_Best_Practices.pdf'
        },
      ]
    },
    {
      category: 'Chat',
      resourceConfig: [
        {
          label: 'Chat Program Overview',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_Chat_Program_Overview.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_Chat_Program_Overview.pdf'
        },
        {
          label: 'Chat & E-Commerce Utilization Study',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_Chat_E-Commerce_Utilization_Study.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_Chat_E-Commerce_Utilization_Study.pdf'
        },
      ]
    },
    {
      category: 'Mystery Shop',
      resourceConfig: [
        {
          label: 'INFINITI Mystery Shop Online Sales Best Practice Guide',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_Mystery_Shop_Online_Sales_Best_Practice_Guide_5-11-2023.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_Mystery_Shop_Online_Sales_Best_Practice_Guide_5-11-2023.pdf'
        },{
          label: 'INFINITI Mystery Shop Online E-Commerce Best Practice Guide',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/E-Commerce_Mystery_Shop_User_Guide_5-11-2023.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/E-Commerce_Mystery_Shop_User_Guide_5-11-2023.pdf'
        },
        {
          label: 'INFINITI E-Commerce Mystery Shop Best Practices',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_MysteryShop_Best_Practices_1.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_MysteryShop_Best_Practices_1.pdf'
        },
        {
          label: 'INFINITI E-Commerce Mystery Shop Best Practices',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_E-Commerce_MysteryShop_Best_Practices_2.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_E-Commerce_MysteryShop_Best_Practices_2.pdf'
        },
      ]
    },
    {
      category: 'Lead Management',
      resourceConfig: [
        {
          label: 'INFINITI Lead Management Program Overview',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_Lead_Management_Program_Overview.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_Lead_Management_Program_Overview.pdf'
        },
        {
          label: 'Third Party Lead Best Practices',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_Third_Party_Lead_Best_Practices.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_Third_Party_Lead_Best_Practices.pdf'
        },
      ]
    },
    {
      category: 'Website',
      resourceConfig: [
        {
          label: 'Website Best Practices',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/INFINITI_BP_Website.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/INFINITI_BP_Website.pdf'
        },
        {
          label: 'Inventory Merchandising Best Practices',
          imgUrl: '../../assets/images/DealerResources/Infiniti/DealerResources/Inventory_Merchandising.jpg',
          downloadUrl: 'https://infinitiprod.blob.core.windows.net:443/dealer-resources/Inventory_Merchandising.pdf'
        },
      ]
    }
  ];
}
