import { IDefaultTableColumnConfig, ISourceSummaryConfig, IDualAxisLineGraphConfig, INameValuePair } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class SourceSummaryConfig implements ISourceSummaryConfig {

  public reportTitle = 'Lead Source Performance';
  public hasTrendTable: boolean = true;

  metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];

  useDefaultComparisonColumns = true;

  public graphConfig: IDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    availableMetrics: [
      {
        id: Enums.leadMetrics.leadVolume.metricId,
        displayName: 'Leads',
        propertyName: Enums.leadMetrics.leadVolume.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // In PMA Leads
      {
        id: Enums.leadMetrics.leadVolumePma.metricId,
        displayName: 'In PMA Leads',
        propertyName: Enums.leadMetrics.leadVolumePma.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Outside PMA Leads
      {
        id: Enums.leadMetrics.leadVolumeNonPma.metricId,
        displayName: 'Outside PMA Leads',
        propertyName: Enums.leadMetrics.leadVolumeNonPma.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Avg. Daily Volume
      {
        id: Enums.leadMetrics.averageDailyVolume.metricId,
        displayName: 'Avg. Daily Volume',
        propertyName: 'averageDailyVolume',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Sales
      {
        id: Enums.leadMetrics.bestMatchSales.metricId,
        displayName: 'Sales',
        propertyName: 'bestMatchSales',
        metricFormatKey: constants.formatKeys.localeString
      },
      //In PMA Sales
      {
        id: Enums.leadMetrics.leadSalesPma.metricId,
        displayName: 'In PMA Sales',
        propertyName: 'bestMatchSalesPma',
        metricFormatKey: constants.formatKeys.localeString
      },
      //Outside PMA Sales
      {
        id: Enums.leadMetrics.leadSalesNonPma.metricId,
        displayName: 'Outside PMA Sales',
        propertyName: 'bestMatchSalesNonPma',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.leadMetrics.responseRate.metricId,
        displayName: 'Response Rate',
        propertyName: Enums.leadMetrics.responseRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.responseRate30.metricId,
        displayName: 'Response Rate < 30 Min',
        propertyName: Enums.leadMetrics.responseRate30.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.responseRate45.metricId,
        displayName: 'Response Rate < 45 Min',
        propertyName: Enums.leadMetrics.responseRate45.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.responseRate60.metricId,
        displayName: 'Response Rate < 1hr',
        propertyName: Enums.leadMetrics.responseRate60.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.appointments.metricId,
        displayName: Enums.leadMetrics.appointments.name,
        propertyName: Enums.leadMetrics.appointments.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.leadMetrics.appointmentRate.metricId,
        displayName: 'Appointment Rate',
        propertyName: Enums.leadMetrics.appointmentRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.bestMatchCloseRate.metricId,
        displayName: 'Close Rate',
        propertyName: 'bestMatchCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 30 Day Close Rate
      {
        id: Enums.leadMetrics.bestMatchCloseRate30.metricId,
        displayName: '30-Day Close Rate',
        propertyName: 'bestMatchCloseRate30',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 60 Day Close Rate
      {
        id: Enums.leadMetrics.bestMatchCloseRate60.metricId,
        displayName: '60-Day Close Rate',
        propertyName: 'bestMatchCloseRate60',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 90 Day Close Rate
      {
        id: Enums.leadMetrics.bestMatchCloseRate90.metricId,
        displayName: '90-Day Close Rate',
        propertyName: 'bestMatchCloseRate90',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 120 Day Close Rate
      {
        id: Enums.leadMetrics.bestMatchCloseRate120.metricId,
        displayName: '120-Day Close Rate',
        propertyName: 'bestMatchCloseRate120',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.conversionRate.metricId,
        displayName: 'Household Conversion',
        propertyName: 'householdCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 30 Day Household Conversion
      {
        id: Enums.leadMetrics.conversionRate30.metricId,
        displayName: '30-Day Household Conversion',
        propertyName: 'householdCloseRate30',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 60 Day Household Conversion
      {
        id: Enums.leadMetrics.conversionRate60.metricId,
        displayName: '60-Day Household Conversion',
        propertyName: 'householdCloseRate60',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 90 Day Household Conversion
      {
        id: Enums.leadMetrics.conversionRate90.metricId,
        displayName: '90-Day Household Conversion',
        propertyName: 'householdCloseRate90',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 120 Day Household Conversion
      {
        id: Enums.leadMetrics.conversionRate120.metricId,
        displayName: '120-Day Household Conversion',
        propertyName: 'householdCloseRate120',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Average Communications
      {
          id: Enums.leadMetrics.averageCommunications.metricId,
          displayName: 'Avg. Communications',
          propertyName: 'averageCommunications',
          metricFormatKey: constants.formatKeys.roundToTenth
      }
    ],
    defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
    defaultMetricTwoId: Enums.leadMetrics.bestMatchSales.metricId,
    metricOneColorHexCode: '#D0573a',
    metricTwoColorHexCode: '#646464'
  }

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        {
            show: true,
            header: 'Leads',
            columnDef: 'leadVolume',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
          show: true,
          header: 'In PMA Leads',
          columnDef: 'leadVolumePma',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
          show: true,
          header: 'Outside PMA Leads',
          columnDef: 'leadVolumeNonPma',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
            show: true,
            header: 'Avg. Daily Volume',
            columnDef: 'averageDailyVolume',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.roundToHundreth,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Sales',
            columnDef: 'bestMatchSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
          show: true,
          header: 'In PMA Sales',
          columnDef: 'bestMatchSalesPma',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
          show: true,
          header: 'Outside PMA Sales',
          columnDef: 'bestMatchSalesNonPma',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
          show: true,
          header: 'Response Rate',
          columnDef: 'responseRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
          show: true,
          header: 'Response Rate < 30 Min',
          columnDef: 'responseRate30',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
          show: true,
          header: 'Response Rate < 45 Min',
          columnDef: 'responseRate45',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
          show: true,
          header: 'Response Rate < 1hr',
          columnDef: 'responseRate60',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
          show: true,
          header: 'Appointment Set',
          columnDef: 'appointments',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
          show: true,
          header: 'Appointment Rate',
          columnDef: 'appointmentRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
            show: true,
            header: 'Close Rate',
            columnDef: 'bestMatchCloseRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        // 30-Day Close Rate
        {
          show: true,
          header: '30-Day Close Rate',
          columnDef: 'bestMatchCloseRate30',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        // 60 Day Close Rate
        {
          show: true,
          header: '60-Day Close Rate',
          columnDef: 'bestMatchCloseRate60',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        // 90 Day Close Rate
        {
          show: true,
          header: '90-Day Close Rate',
          columnDef: 'bestMatchCloseRate90',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        // 120 Day Close Rate
        {
          show: true,
          header: '120-Day Close Rate',
          columnDef: 'bestMatchCloseRate120',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        {
            show: true,
            header: 'Household Conversion',
            columnDef: 'householdCloseRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        //30 Day Household Conversion
        {
          show: true,
          header: '30-Day Household Conversion',
          columnDef: 'householdCloseRate30',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        //60 Day Household Conversion
        {
          show: true,
          header: '60-Day Household Conversion',
          columnDef: 'householdCloseRate60',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        //90 Day Household Conversion
        {
          show: true,
          header: '90-Day Household Conversion',
          columnDef: 'householdCloseRate90',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        //120 Day Household Conversion
        {
          show: true,
          header: '120-Day Household Conversion',
          columnDef: 'householdCloseRate120',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
        // Avg. Communications
        {
          show: true,
          header: 'Avg. Communications',
          columnDef: 'averageCommunications',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.roundToTenth,
          comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
          clickable: false
        },
    ];
    public trendTableMetrics: INameValuePair[] = [
      {name: 'Leads', value: 'leadVolume'},
      {name: 'In PMA Leads', value: 'leadVolumePma'},
      {name: 'Outside PMA Leads', value: 'leadVolumeNonPma'},
      {name: 'Avg. Daily Volume', value: 'averageDailyVolume'},
      {name: 'Sales', value: 'bestMatchSales'},
      {name: 'In PMA Sales', value: 'bestMatchSalesPma'},
      {name: 'Outside PMA Sales', value: 'bestMatchSalesNonPma'},
      {name: 'Response Rate', value: 'responseRate'},
      {name: 'Response Rate < 30 Min', value: 'responseRate30'},
      {name: 'Response Rate < 45 Min', value: 'responseRate45'},
      {name: 'Response Rate < 1hr', value: 'responseRate60'},
      {name: 'Appointment Set', value: 'appointments'},
      {name: 'Appointment Rate', value: 'appointmentRate'},
      {name: 'Close Rate', value: 'bestMatchCloseRate'},
      {name: '30-Day Close Rate', value: 'bestMatchCloseRate30'},
      {name: '60-Day Close Rate', value: 'bestMatchCloseRate60'},
      {name: '90-Day Close Rate', value: 'bestMatchCloseRate90'},
      {name: '120-Day Close Rate', value: 'bestMatchCloseRate120'},
      {name: 'Household Conversion', value: 'householdCloseRate'},
      {name: '30-Day Household Conversion', value: 'householdCloseRate30'},
      {name: '60-Day Household Conversion', value: 'householdCloseRate60'},
      {name: '90-Day Household Conversion', value: 'householdCloseRate90'},
      {name: '120-Day Household Conversion', value: 'householdCloseRate120'},
      {name: 'Avg. Communications', value: 'averageCommunications'},
    ];
}
