import {
  ILeadsOverviewBillboardConfig,
  ILeadsOverviewConfig,
  ILeadsOverviewDealerKpiConfig,
  IDefaultTableColumnConfig,
  ILeadsOverviewKpiConfig,
  ILeadsOverviewSourceTypeCardConfig,
  IPropertyOverride
} from '../../../../_shared/models/models-index';

import * as constants from '../../../../_shared/constants/constants';
export class LeadsOverviewConfig implements ILeadsOverviewConfig {

  public reportTitle = 'Leads Overview';
  metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];

  useDefaultComparisonColumns = true;

  public dealerLevelMetricOverrides: IPropertyOverride[] = [
    {
      baseProperty: 'bestMatchCloseRate',
      overrideProperty: 'sameDealerBestMatchCloseRate'
    }
  ];

  public sourceTypeCardConfig: ILeadsOverviewSourceTypeCardConfig = {
    showComparisonMetrics: false,
    leadMetric: {
      name: 'Unique Leads',
      value: 'uniqueLeads'
    },
    closeRateMetric: {
      name: 'Close Rate',
      value: 'closeRate'
    }
  }

  public billboards: ILeadsOverviewBillboardConfig[] = [
    {
      title: 'Lead Volume',
      subtitle: '',
      iconClass: 'fa-users fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadVolume',
      metricMOMPropertyName: 'leadVolumeMOM',
      metricYOYPropertyName: 'leadVolumeYOY',
      metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
      metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
      metricPreviousYearPropertyName: 'leadVolumePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'leadVolumeMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'LeadsOverviewLeadVolumeBillboardHelp',
      helpTextTitle: 'Lead Volume'
    },
    {
      title: 'Close Rate',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'bestMatchCloseRate',
      metricMOMPropertyName: 'bestMatchCloseRateMOM',
      metricYOYPropertyName: 'bestMatchCloseRateYOY',
      metricPreviousMonthPropertyName: 'bestMatchCloseRatePreviousMonth',
      metricPreviousMTDPropertyName: 'bestMatchCloseRatePreviousMTD',
      metricPreviousYearPropertyName: 'bestMatchCloseRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'bestMatchCloseRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewCloseRateBillboardHelp',
      helpTextTitle: '% Close Rate'
    },
    {
      title: 'Response Rate < 45 Min',
      subtitle: '',
      iconClass: 'fa-clock-o fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'responseRate45',
      metricMOMPropertyName: 'responseRate45MOM',
      metricYOYPropertyName: 'responseRate45YOY',
      metricPreviousMonthPropertyName: 'responseRate45PreviousMonth',
      metricPreviousMTDPropertyName: 'responseRate45PreviousMTD',
      metricPreviousYearPropertyName: 'responseRate45PreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'responseRate45MOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewResponded45BillboardHelp',
      helpTextTitle: 'Response Rate < 45 Min'
    },
    {
      title: 'Appointment Rate',
      subtitle: '',
      iconClass: 'fa-calendar fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'appointmentRate',
      metricMOMPropertyName: 'appointmentRateMOM',
      metricYOYPropertyName: 'appointmentRateYOY',
      metricPreviousMonthPropertyName: 'appointmentRatePreviousMonth',
      metricPreviousMTDPropertyName: 'appointmentRatePreviousMTD',
      metricPreviousYearPropertyName: 'appointmentRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'appointmentRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewAppointmentRateBillboardHelp',
      helpTextTitle: 'Appointment Rate'
    },
  ];

  public dealerbillboards: ILeadsOverviewBillboardConfig[] = [
    {
      title: 'Lead Volume',
      subtitle: '',
      iconClass: 'fa-users fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadVolume',
      metricMOMPropertyName: 'leadVolumeMOM',
      metricYOYPropertyName: 'leadVolumeYOY',
      metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
      metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
      metricPreviousYearPropertyName: 'leadVolumePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'leadVolumeMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'LeadsOverviewLeadVolumeBillboardHelp',
      helpTextTitle: 'Lead Volume'
    },
    {
      title: 'Close Rate',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'sameDealerBestMatchCloseRate',
      metricMOMPropertyName: 'sameDealerBestMatchCloseRateMOM',
      metricYOYPropertyName: 'sameDealerBestMatchCloseRateYOY',
      metricPreviousMonthPropertyName: 'sameDealerBestMatchCloseRatePreviousMonth',
      metricPreviousMTDPropertyName: 'sameDealerBestMatchCloseRatePreviousMTD',
      metricPreviousYearPropertyName: 'sameDealerBestMatchCloseRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'sameDealerBestMatchCloseRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewCloseRateBillboardHelp',
      helpTextTitle: 'Close Rate'
    },
    {
      title: 'Response Rate < 45 Min',
      subtitle: '',
      iconClass: 'fa-clock-o fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'responseRate45',
      metricMOMPropertyName: 'responseRate45MOM',
      metricYOYPropertyName: 'responseRate45YOY',
      metricPreviousMonthPropertyName: 'responseRate45PreviousMonth',
      metricPreviousMTDPropertyName: 'responseRate45PreviousMTD',
      metricPreviousYearPropertyName: 'responseRate45PreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'responseRate45MOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewResponded45BillboardHelp',
      helpTextTitle: 'Response Rate < 45 Min'
    },
    {
      title: 'Appointment Rate',
      subtitle: '',
      iconClass: 'fa-calendar fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'appointmentRate',
      metricMOMPropertyName: 'appointmentRateMOM',
      metricYOYPropertyName: 'appointmentRateYOY',
      metricPreviousMonthPropertyName: 'appointmentRatePreviousMonth',
      metricPreviousMTDPropertyName: 'appointmentRatePreviousMTD',
      metricPreviousYearPropertyName: 'appointmentRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'appointmentRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewAppointmentRateBillboardHelp',
      helpTextTitle: 'Appointment Rate'
    },
  ];

  public dealerKpiCardConfig: ILeadsOverviewDealerKpiConfig = {
    metrics: {
      'CloseRate': {
        yAxisTitle: 'Close Rate',
        displayName: 'Close Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'sameDealerBestMatchCloseRate',
        metricFormatKey: null,
        formatter: null
      },
      'LeadVolume': {
        yAxisTitle: 'Lead Volume',
        displayName: 'Lead Volume',
        pointLabelFormat: null,
        propertyName: 'leadVolume',
        metricFormatKey: null,
        formatter: null
      },
      'ResponseRate': {
        yAxisTitle: '% Responded < 45 Min',
        displayName: 'Responded < 45 Min',
        pointLabelFormat: '{value}%',
        propertyName: 'responseRate45',
        metricFormatKey: null,
        formatter: null
      }
    }
  };

  public kpiCardConfig: ILeadsOverviewKpiConfig = {
    metrics: {
      'ResponseRate': {
        yAxisTitle: '% Responded < 45 Min',
        displayName: 'Response Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'responseRate45',
        metricFormatKey: constants.formatKeys.percentage,
        formatter: (val) => Math.round(val * 100)
      },
      'CloseRate': {
        yAxisTitle: 'Close Rate',
        displayName: 'Close Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'bestMatchCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        formatter: (val) => Math.round(val * 10000) / 100
      }
    }
  };

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Retailer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    // Lead Volume
    {
      show: true, header: 'Lead Volume',
      columnDef: 'leadVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // Response Rate < 30 min
    {
      show: true,
      header: 'Response Rate < 30 Min',
      columnDef: 'responseRate30',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // Response Rate < 45 min
    {
      show: true,
      header: 'Response Rate < 45 Min',
      columnDef: 'responseRate45',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // Response Rate < 1 hr
    {
      show: true,
      header: 'Response Rate < 1hr',
      columnDef: 'responseRate60',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // Average Response Time
    {
      show: true,
      header: 'Avg Response Time',
      columnDef: 'averageResponseTime',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.minutesTimeString,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // Appointment Set
    {
      show: true,
      header: 'Appointment Set',
      columnDef: 'appointments',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // Appointment Rate
    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // Close Rate
    {
      show: true,
      header: 'Close Rate',
      columnDef: 'bestMatchCloseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // 30 Day Close Rate
    {
      show: true,
      header: '30-Day Close Rate',
      columnDef: 'bestMatchCloseRate30',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // 60 Day Close Rate
    {
      show: true,
      header: '60-Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // 90 Day Close Rate
    {
      show: true,
      header: '90-Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // Avg. Communications
    {
      show: true,
      header: 'Avg. Communications',
      columnDef: 'averageCommunications',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.roundToTenth,
      comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
  ];
}
