import {
  IDefaultTableColumnConfig,
  IDigitalRetailingOverviewBillboardConfig,
  IDigitalRetailingOverviewConfig,
  IDualAxisLineGraphConfig,
  IMultiLevelDualAxisLineGraphConfig
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';

export class DigitalRetailingOverviewConfig implements IDigitalRetailingOverviewConfig {
  public useV5 = true;
  public useDefaultComparisonColumns = true;
  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];
  public reportTitle = 'E-Commerce';
  public dealerTableLabel = 'Retailer Summary';
  public showEngagementAnalysis = false;
  public chartSeriesColors = ['#384752', '#52616C', '#05141F'];
  public billboards: IDigitalRetailingOverviewBillboardConfig[] = [{
    title: 'Session Start Rate',
    subtitle: null,
    iconClass: 'fa-users fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'sessionStartRate',
    metricMOMPropertyName: 'sessionStartRateMOM',
    metricYOYPropertyName: 'sessionStartRateYOY',
    metricPreviousMonthPropertyName: 'sessionStartRatePreviousMonth',
    metricPreviousMTDPropertyName: 'sessionStartRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: true,
    trendArrowDrivingPropertyName: 'sessionStartRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'DigitalRetailingOverviewSessionStartRateBillboardHelp',
    helpTextTitle: 'Session Start Rate',
  }, {
    title: 'Credit App Rate',
    subtitle: null,
    iconClass: 'fa-calculator fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'creditAppRate',
    metricMOMPropertyName: 'creditAppRateMOM',
    metricYOYPropertyName: 'creditAppRateYOY',
    metricPreviousMonthPropertyName: 'creditAppRatePreviousMonth',
    metricPreviousMTDPropertyName: 'creditAppRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: true,
    trendArrowDrivingPropertyName: 'creditAppRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'DigitalRetailingOverviewCreditAppRateBillboardHelp',
    helpTextTitle: 'Credit App Rate',
  }, {
    title: 'Form Submission Rate',
    subtitle: null,
    iconClass: 'fa-user-circle fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'formSubmissionRate',
    metricMOMPropertyName: 'formSubmissionRateMOM',
    metricYOYPropertyName: 'formSubmissionRateYOY',
    metricPreviousMonthPropertyName: 'formSubmissionRatePreviousMonth',
    metricPreviousMTDPropertyName: 'formSubmissionRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: true,
    trendArrowDrivingPropertyName: 'formSubmissionRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'DigitalRetailingFormSubmissionsRateBillboardHelp',
    helpTextTitle: 'Form Submission Rate',
  }, {
    title: 'E-Commerce Appointment Rate',
    subtitle: null,
    iconClass: 'fa-check-square-o fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'eCommerceAppointmentRate',
    metricMOMPropertyName: 'eCommerceAppointmentRateMOM',
    metricYOYPropertyName: 'eCommerceAppointmentRateYOY',
    metricPreviousMonthPropertyName: 'eCommerceAppointmentRatePreviousMonth',
    metricPreviousMTDPropertyName: 'eCommerceAppointmentRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: true,
    trendArrowDrivingPropertyName: 'eCommerceAppointmentRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'DigitalRetailingOverviewAppointmentRateBillboardHelp',
    helpTextTitle: 'E-Commerce Appointment Rate',
  }];
  public kpiSheetOptions = {
    overview: [{
      name: 'providerId',
      label: 'Provider',
      isHeader: true
    }, {
      name: 'uniqueShoppers',
      label: 'Unique Shoppers',
    },{
      name: 'paymentCalculatorRate',
      label: 'Payment Calc Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'tradeInRate',
      label: 'Trade-In Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'accessoriesRate',
      label: 'Accessories Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'fICompleteRate',
      label: 'F&I Complete Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'formSubmissionRate',
      label: 'Form Submission Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'creditAppRate',
      label: 'Credit App Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'eCommerceAppointmentRate',
      label: 'E-Commerce Appointment Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }]
  };
  public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      // Website Visits
      {
        id: Enums.digitalRetailingMetrics.visits.metricId,
        displayName: Enums.digitalRetailingMetrics.visits.name,
        propertyName: 'websiteVisits',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Website Visitors
      {
        id: Enums.digitalRetailingMetrics.visitors.metricId,
        displayName: Enums.digitalRetailingMetrics.visitors.name,
        propertyName: 'websiteVisitors',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Session Starts
      {
        id: Enums.digitalRetailingMetrics.sessionStarts.metricId,
        displayName: Enums.digitalRetailingMetrics.sessionStarts.name,
        propertyName: 'sessionStarts',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Unique Shoppers
      {
        id: Enums.digitalRetailingMetrics.uniqueShoppers.metricId,
        displayName: Enums.digitalRetailingMetrics.uniqueShoppers.name,
        propertyName: 'uniqueShoppers',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Session Start Rate
      {
        id: Enums.digitalRetailingMetrics.sessionStartRate.metricId,
        displayName: Enums.digitalRetailingMetrics.sessionStartRate.name,
        propertyName: 'sessionStartRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Payment Calculators
      {
        id: Enums.digitalRetailingMetrics.paymentCalculators.metricId,
        displayName: Enums.digitalRetailingMetrics.paymentCalculators.name,
        propertyName: 'paymentCalculators',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Payment Calculator Rate
      {
        id: Enums.digitalRetailingMetrics.paymentCalculatorRate.metricId,
        displayName: Enums.digitalRetailingMetrics.paymentCalculatorRate.name,
        propertyName: 'paymentCalculatorRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Incentives
      {
        id: Enums.digitalRetailingMetrics.incentives.metricId,
        displayName: Enums.digitalRetailingMetrics.incentives.name,
        propertyName: 'incentives',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Incentive Rate
      {
        id: Enums.digitalRetailingMetrics.incentiveRate.metricId,
        displayName: Enums.digitalRetailingMetrics.incentiveRate.name,
        propertyName: 'incentiveRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Trade-Ins
      {
        id: Enums.digitalRetailingMetrics.tradeIns.metricId,
        displayName: Enums.digitalRetailingMetrics.tradeIns.name,
        propertyName: 'tradeIns',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Trade-In Rate
      {
        id: Enums.digitalRetailingMetrics.tradeInRate.metricId,
        displayName: Enums.digitalRetailingMetrics.tradeInRate.name,
        propertyName: 'tradeInRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Accessories
      {
        id: Enums.digitalRetailingMetrics.accessories.metricId,
        displayName: Enums.digitalRetailingMetrics.accessories.name,
        propertyName: 'accessories',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Accessories Rate
      {
        id: Enums.digitalRetailingMetrics.accessoriesRate.metricId,
        displayName: Enums.digitalRetailingMetrics.accessoriesRate.name,
        propertyName: 'accessoriesRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // F & I Completes
      {
        id: Enums.digitalRetailingMetrics.financeFinishes.metricId,
        displayName: 'F&I Completes',
        propertyName: 'fICompletes',
        metricFormatKey: constants.formatKeys.localeString
      },
      // F & I Complete Rate
      {
        id: Enums.digitalRetailingMetrics.financeFinishRate.metricId,
        displayName: 'F&I Complete Rate',
        propertyName: 'fICompleteRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Form Submission
      {
        id: Enums.digitalRetailingMetrics.formSubmissions.metricId,
        displayName: Enums.digitalRetailingMetrics.formSubmissions.name,
        propertyName: 'leadCaptures',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Form Submission Rate
      {
        id: Enums.digitalRetailingMetrics.formSubmissionRate.metricId,
        displayName: Enums.digitalRetailingMetrics.formSubmissionRate.name,
        propertyName: 'formSubmissionRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // All Credit Apps
      {
        id: Enums.digitalRetailingMetrics.allCreditApps.metricId,
        displayName: Enums.digitalRetailingMetrics.allCreditApps.name,
        propertyName: 'allCreditApps',
        metricFormatKey: constants.formatKeys.localeString
      },
      // All Credit Apps Rate
      {
        id: Enums.digitalRetailingMetrics.allCreditAppRate.metricId,
        displayName: Enums.digitalRetailingMetrics.allCreditAppRate.name,
        propertyName: 'allCreditAppsRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Deposits
      {
        id: Enums.digitalRetailingMetrics.deposits.metricId,
        displayName: Enums.digitalRetailingMetrics.deposits.name,
        propertyName: 'deposits',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Deposit Rate
      {
        id: Enums.digitalRetailingMetrics.depositRate.metricId,
        displayName: Enums.digitalRetailingMetrics.depositRate.name,
        propertyName: 'depositRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // E-Contracting
      {
        id: Enums.digitalRetailingMetrics.eContracting.metricId,
        displayName: Enums.digitalRetailingMetrics.eContracting.name,
        propertyName: 'eContracting',
        metricFormatKey: constants.formatKeys.localeString
      },
      // E-Contracting Rate
      {
        id: Enums.digitalRetailingMetrics.eContractingRate.metricId,
        displayName: Enums.digitalRetailingMetrics.eContractingRate.name,
        propertyName: 'eContractingRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Pre-Qualified
      {
        id: Enums.digitalRetailingMetrics.preQualifieds.metricId,
        displayName: Enums.digitalRetailingMetrics.preQualifieds.name,
        propertyName: 'preQualified',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Pre-Qualified Rate
      {
        id: Enums.digitalRetailingMetrics.preQualifiedRate.metricId,
        displayName: Enums.digitalRetailingMetrics.preQualifiedRate.name,
        propertyName: 'preQualifiedRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Credit Apps
      {
        id: Enums.digitalRetailingMetrics.creditApps.metricId,
        displayName: Enums.digitalRetailingMetrics.creditApps.name,
        propertyName: 'creditApps',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Credit App Rate
      {
        id: Enums.digitalRetailingMetrics.creditAppRate.metricId,
        displayName: Enums.digitalRetailingMetrics.creditAppRate.name,
        propertyName: 'creditAppRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // E-Commerce Appointments
      {
        id: Enums.digitalRetailingMetrics.appointments.metricId,
        displayName: 'E-Commerce Appointments',
        propertyName: 'eCommerceAppointments',
        metricFormatKey: constants.formatKeys.localeString
      },
      // E-Commerce Appointment Rate
      {
        id: Enums.digitalRetailingMetrics.appointmentRate.metricId,
        displayName: 'E-Commerce Appointment Rate',
        propertyName: 'eCommerceAppointmentRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }
    ],
    childMetrics: [
      {
        id: Enums.chatProviders.allProviders.providerId,
        displayName: Enums.chatProviders.allProviders.displayName,
        propertyName: Enums.chatProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 15,
        displayName: 'CarNow',
        propertyName: 'CarNow',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 16,
        displayName: 'CDK',
        propertyName: 'CDK',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 17,
        displayName: 'TagRail',
        propertyName: 'TagRail',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 19,
        displayName: 'Gubagoo',
        propertyName: 'Gubagoo',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 25,
        displayName: 'Darwin',
        propertyName: 'Darwin Automotive',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
    ],
    defaultChildrenIds: [Enums.chatProviders.allProviders.providerId, Enums.chatProviders.allProviders.providerId],
    defaultParentIds: [Enums.digitalRetailingMetrics.sessionStarts.metricId, Enums.digitalRetailingMetrics.appointmentRate.metricId],
    metricOneColorHexCode: '#D0573a',
    metricTwoColorHexCode: '#646464'
  };
  public dealerTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    }, {
      show: false,
      print: true,
      header: 'Retailer Name',
      columnDef: 'displayName',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    // website Visits
    {
      show: true,
      header: 'Website Visits',
      columnDef: 'websiteVisits',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // website Visitors
    {
      show: true,
      header: 'Website Visitors',
      columnDef: 'websiteVisitors',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Session Starts
    {
      show: true,
      header: 'Session Starts',
      columnDef: 'sessionStarts',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Unique Shoppers
    {
      show: true,
      header: 'Unique Shoppers',
      columnDef: 'uniqueShoppers',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Session Start Rate
    {
      show: true,
      header: 'Session Start Rate',
      columnDef: 'sessionStartRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Payment Calculators
    {
      show: true,
      header: 'Payment Calculators',
      columnDef: 'paymentCalculators',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Payment Calculator Rate
    {
      show: true,
      header: 'Payment Calculator Rate',
      columnDef: 'paymentCalculatorRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Incentives
    {
      show: true,
      header: 'Incentives',
      columnDef: 'incentives',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Incentive Rate
    {
      show: true,
      header: 'Incentive Rate',
      columnDef: 'incentiveRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Trade-Ins
    {
      show: true,
      header: 'Trade-Ins',
      columnDef: 'tradeIns',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Trade-In Rate
    {
      show: true,
      header: 'Trade-In Rate',
      columnDef: 'tradeInRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Accessories
    {
      show: true,
      header: 'Accessories',
      columnDef: 'accessories',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Accessories Rate
    {
      show: true,
      header: 'Accessories Rate',
      columnDef: 'accessoriesRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // F & I Completions
    {
      show: true,
      header: 'F & I Completes',
      columnDef: 'fICompletes',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // F & I Completions Rate
    {
      show: true,
      header: 'F & I Complete Rate',
      columnDef: 'fICompleteRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Form Submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Form Submission Rate
    {
      show: true,
      header: 'Form Submission Rate',
      columnDef: 'formSubmissionRate',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false,
      hasComparisonMetrics: true
    },
    // All Credit Apps
    {
      show: true,
      header: 'All Credit Apps',
      columnDef: 'allCreditApps',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // All Credit App Rate
    {
      show: true,
      header: 'All Credit Apps Rate',
      columnDef: 'allCreditAppsRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Deposits
    {
      show: true,
      header: 'Deposits',
      columnDef: 'deposits',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Deposit Rate
    {
      show: true,
      header: 'Deposit Rate',
      columnDef: 'depositRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // E-Contracting
    {
      show: true,
      header: 'E-Contracting',
      columnDef: 'eContracting',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // E-Contracting Rate
    {
      show: true,
      header: 'E-Contracting Rate',
      columnDef: 'eContractingRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Pre-Qualified
    {
      show: true,
      header: 'Pre-Qualified',
      columnDef: 'preQualified',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Pre-Qualified Rate
    {
      show: true,
      header: 'Pre-Qualified Rate',
      columnDef: 'preQualifiedRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Credit Apps
    {
      show: true,
      header: 'Credit Apps',
      columnDef: 'creditApps',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Credit App Rate
    {
      show: true,
      header: 'Credit App Rate',
      columnDef: 'creditAppRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Appointments
    {
      show: true,
      header: 'E-Commerce Appointments',
      columnDef: 'eCommerceAppointments',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Appointment Rate
    {
      show: true,
      header: 'E-Commerce Appointment Rate',
      columnDef: 'eCommerceAppointmentRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    }
  ];
}
