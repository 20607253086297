import {
    IDefaultTableColumnConfig,
    ISalesVehicleConfig,
    IMultiLevelDualAxisLineGraphConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class SalesVehicleConfig implements ISalesVehicleConfig {
    public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
            {
                id: Enums.saleMetrics.totalSales.metricId,
                displayName: Enums.saleMetrics.totalSales.name,
                propertyName: Enums.saleMetrics.totalSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.saleMetrics.leadSales.metricId,
                displayName: Enums.saleMetrics.leadSales.name,
                propertyName: Enums.saleMetrics.leadSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.saleMetrics.leadContribution.metricId,
                displayName: Enums.saleMetrics.leadContribution.name,
                propertyName: Enums.saleMetrics.leadContribution.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.saleMetrics.averageTimeToSale.metricId,
                displayName: Enums.saleMetrics.averageTimeToSale.name,
                propertyName: Enums.saleMetrics.averageTimeToSale.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.saleMetrics.dealerAverageLostSales.metricId,
                displayName: 'Retailer Avg. Lost Sales',
                propertyName: Enums.saleMetrics.dealerAverageLostSales.nameKey,
                metricFormatKey: constants.formatKeys.roundToHundreth
            },
        ],
        childMetrics: [], // this needs to be data driven

        defaultChildrenIds: ['All Models', 'All Models'],
        defaultParentIds: [Enums.saleMetrics.totalSales.metricId, Enums.saleMetrics.leadSales.metricId],
        metricOneColorHexCode: '#D0573a',
        metricTwoColorHexCode: '#646464'
    };
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Vehicle Model',
            columnDef: 'entity',
            hasComparisonMetrics: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        {
            show: true,
            header: 'Total Sales',
            columnDef: 'totalSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
        {
            show: true,
            header: 'Sales From Leads',
            columnDef: 'leadSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
        {
            show: true,
            header: 'Lead Contr. Rate',
            columnDef: 'leadContribution',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
        {
            show: true,
            header: 'Avg. Time to Sale',
            columnDef: 'averageTimeToSale',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.integerDaysOrNa,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
        {
            show: true,
            header: 'Retailer Avg. Lost Sales',
            columnDef: 'lostSalesDealerAverage',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.roundToHundreth,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            clickable: false
        },
    ];
}
